/** @format */

.loading__game {
	// background-image: url("../../../../../../assets/roll/loading/slime_bgc.jpg") !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
}
.loading__game {
	// background-image: url("../../../../../../assets/images/teleImage/loadingImg.webp") !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	display: flex;
	align-self: center;
	justify-self: center;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 410px;
	// background-image: url("../../../../../assets/images/teleImage/4.webp") !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	max-width: 400px;
	margin: auto;
}

.logo {
	width: 50px;
	height: 50px;
	margin-bottom: 20px;
}

.title {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 10px;
	color: #071f54;
}

.subtitle {
	font-size: 15px;
	color: #555;
	margin-bottom: 20px;
	text-align: center;
}

.loginButton {
	background-color: #2c6a32;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	margin-bottom: 20px;
	transition: background-color 0.3s;

	&:hover {
		background-color: #255527;
	}
}

.iconList {
	display: flex;
	justify-content: space-between;
	width: 80%;
	margin: 0 auto;

	.iconItem {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 14px;
		color: #333;

		a {
			text-decoration: none;
			color: inherit;
			transition: color 0.3s;

			&:hover {
				color: #2c6a32;
			}
		}

		.icon {
			margin-bottom: 5px;
			font-size: 24px;
		}
	}
}
// Button.module.scss
.connectButton {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	border-radius: 26px;
	border: none;
	cursor: pointer;
	transition: background-color 0.3s ease;
	// margin: 10px;

	&:focus {
		outline: none;
		box-shadow: 0 0 0 3px rgba(0, 82, 255, 0.5);
	}

	img {
		margin-right: 10px;
		width: 24px;
		height: 24px;
	}
}

.connectCoinbaseButton {
	@extend .connectButton;
	background-color: #071f54;
	color: #ffffff;

	&:hover {
		background-color: #071f54;
	}

	&:active {
		background-color: #071f54;
	}
}

.connectMetamaskButton {
	@extend .connectButton;
	background-color: #af641e;
	color: #ffffff;
    margin-top: 10px;

	&:hover {
		background-color: #af641e;
	}

	&:active {
		background-color: #af641e;
	}
}
.aboutStyle {
	margin-top: 20px;
	display: flex;
	width: 100%;
	flex-direction: column;
	row-gap: 10px;
}

.home__one {
	text-align: center;
	h2 {
		color: #071f54;
		text-align: center;
		font-family: "Berlin Sans FB Demi";
		font-size: 26.88px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 26.88px */
	}
	img {
		margin: 12px 0px;
	}
	p {
		color: #555;
		text-align: center;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 16.8px */
        margin-bottom: 15px;
	}
}

.home__two {
	text-align: center;
	h2 {
		color: #071f54;
		text-align: center;
		font-family: "Berlin Sans FB Demi";
		font-size: 26.88px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 26.88px */
	}

	p {
		color: #555;
		text-align: center;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 16.8px */
        margin-bottom: 15px;
	}

    &__price {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        margin: 15px 0px;
    }
}

.title__slime {
	color: rgba(0, 0, 0, 0.8);
	text-align: center;
	font-family: "Berlin Sans FB";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 16px */
    margin-top: 15px;
}

@media only screen and (max-width: 480px) {
	.container {
		max-width: 380px;
	}
}
