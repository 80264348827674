.body {
	background-color: white;
	// display: flex;
	position: relative;
	justify-content: center;
	// background-image: url("../../../../assets/roll/bgrmachine.png");
	// background-image: url("../../../../assets/roll/slime_bgc.png");
	width: 100%;
	min-height: 100vh;
	// transform: scale(2.1);
	// left: 22px;
	// top: 28vh
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	overflow: hidden;
}
.connectButton {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	border-radius: 26px;
	border: none;
	cursor: pointer;
	transition: background-color 0.3s ease;
	// margin: 10px;

	&:focus {
		outline: none;
		box-shadow: 0 0 0 3px rgba(0, 82, 255, 0.5);
	}

	img {
		margin-right: 10px;
		width: 24px;
		height: 24px;
	}
}

.connectMetamaskButton {
	@extend .connectButton;
	background-color: #af641e;
	color: #ffffff;

	&:hover {
		background-color: #af641e;
	}

	&:active {
		background-color: #af641e;
	}
}
.aboutStyle {
	margin-top: 20px;
	display: flex;
	width: 100%;
	flex-direction: column;
	row-gap: 10px;
}

.home__one {
	text-align: center;
	h2 {
		color: #071f54;
		text-align: center;
		font-family: "Berlin Sans FB Demi";
		font-size: 26.88px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 26.88px */
	}
	img {
		margin: 12px 0px;
	}
	p {
		color: #555;
		text-align: center;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 16.8px */
        margin-bottom: 15px;
	}
}

.logo {
	width: 50px;
	height: 50px;
	margin-bottom: 20px;
}

.title {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 10px;
	color: #071f54;
}

.subtitle {
	font-size: 15px;
	color: #555;
	margin-bottom: 20px;
	text-align: center;
}


.home__two {
	text-align: center;
	h2 {
		color: #071f54;
		text-align: center;
		font-family: "Berlin Sans FB Demi";
		font-size: 26.88px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 26.88px */
	}

	p {
		color: #555;
		text-align: center;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 16.8px */
        margin-bottom: 15px;
	}

    &__price {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        margin: 15px 0px;
    }
}
.containerWallet {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 84%;
	height: 420px;
	width: 400px;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	row-gap: 20px;
	padding:  0 16px;
	align-content: center;
	justify-content: center;
}
.main1 {
	height: auto;
	width: 100%;
	height: 100vh;
	background-image: url('../../assets/images/teleImage/4.webp') !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	justify-items: center;
	flex-direction: column;
}

.container {
	padding-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	// background-image: url("../../../../assets/roll/Spin_Machine_Full.png");
	// background-image: url("../../../../assets/roll/sprin_machine.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	width: 600px;
	height: 600px;
	align-items: center;
	justify-content: center;
	left: -79px;
	transform: scale(1.4);
	position: absolute;
	bottom: 13px;
	z-index: 0;
}

.spinner {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 99px;
	overflow: hidden;
	margin-top: 0px;
	margin-right: 14px;
	justify-content: space-between;
}

.item {
	font-size: 34px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: transform 0.1s linear;
}
.BtnConnectWallet {
	background: linear-gradient(180deg, #eeca4b 0%, #eb6b23 100%);
	border: none;
	padding: 1.3rem 2rem;
	color: #ffffff;
	font-weight: 600;
	border-radius: 10px;
	cursor: pointer;
}
.button {
	cursor: pointer;
	position: absolute;
	// top: 440px;
	right: 36%;
	bottom: -10px;
	transform: scale(2);
}

.button:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}
.shop__game {
	position: absolute;
	left: 0px;
	top: 16vh;
	z-index: 100;
}
.shop__game__right {
	position: absolute;
	right: 0px;
	top: 16vh;
	z-index: 100;
}

.knife {
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 10;
	.list__knife {
		border-radius: 16px;
		background: rgba(48, 32, 0, 0.2);
		width: 250px;
		height: 25px;
		flex-shrink: 0;
		display: flex;
		justify-content: start;
		gap: 5px;
		align-items: center;
		padding: 5px;
	}
	.count__knife {
		position: relative;
		width: 45px;
		height: 45px;
		left: -20px;
		.vertor {
			position: absolute;
			width: 100%;
			height: 100%;
		}
		.text__count__knife {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
.price {
	top: 48%;
}
@media screen and (width: 430px) {
	.price {
		top: 55%;
	}
}
@media screen and (width: 414px) {
	.price {
		top: 53%;
	}
}
@media screen and (width: 412px) {
	.price {
		top: 53%;
	}
}
@media screen and (width: 360px) {
	.price {
		top: 43%;
	}
}
